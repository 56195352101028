.firebaseui-container {
    max-width: 500px !important;
}

.firebaseui-idp-button,
.firebaseui-tenant-button {
    max-width: 350px !important;
    text-align: center !important;
}

#firebaseui-login-container {
    width: 100% !important;
}

.firebaseui-card-content {
    padding: 0 !important;
}

.firebaseui-idp-text-long {
    flex: 1;
    display: flex;
}

.firebaseui-idp-button {
    display: flex;
    align-items: center;
    padding-left: 0;
    box-shadow: none;
    border: 1px solid #757575;
}

.firebaseui-idp-icon-wrapper {
    position: absolute !important;
    left: 20px;
}
