.tracker-loader {
    position: relative;
}

.tracker-loader-colored {
    height: 4px;
    animation: loader 1s infinite;
    background-color: #009de0;
}

@keyframes loader {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
