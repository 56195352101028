.sections-slick .slick-slide {
    padding: 0 20px;
}

.slick-prev:before {
    color: #009de0 !important;
    font-size: 40px!important;
}
.slick-next:before {
    color: #009de0 !important;
    font-size:40px!important;
}

.arrows-slick .slick-next {
    left:98%;
    top:-50px
}
.arrows-slick .slick-prev {

    left:93%;
    top:-50px
}
@media only screen and (max-width: 1500px) {
    .arrows-slick .slick-next {

        left:93%;
        top:-50px
    }
}
@media only screen and (max-width: 1500px) {
    .arrows-slick .slick-prev {

        left:80%;
        top:-50px
    }
}
@media only screen and (max-width: 600px) {
    .arrows-slick .slick-next {

        left:90%;
        top:-50px
    }
}
@media only screen and (max-width: 600px) {
    .arrows-slick .slick-prev {

        left:70%;
        top:-50px
    }
}

.slick-track {
    float: left;
}
