.datepicker-calendar {
    background-color: white;
    height: 100%;
    align-items: center;
    border: 1px solid #c3c3c3 !important;
}

.datepicker-calendar-wrapper > div,
.datepicker-calendar-wrapper > div > div {
    height: 100%;
}

.xflex-datepicker-control-wrapper {
    height: 100%;
}

.xflex-datepicker-control {
    font-size: 0.9rem !important;
    padding: 13px 20px !important;
    line-height: 1.2 !important;
}

.xflex-datepicker-control #icon-close-solid {
    width: 16px;
}

.airbnb-xflex-datepicker {
    font-size: 0.9rem !important;
    border-style: none !important;
}

.airbnb-xflex-datepicker > span:nth-child(2) {
    margin-left: 10px;
}

.airbnb-search-component {
    transition: transform 100ms ease-in-out;
}

.app-header {
    transition: min-height 100ms ease-in-out background-color 100ms linear;
    top: 0;
    /* width: 100vw; */
}

.app-header-container {
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 999;
}

.headroom {
    z-index: 999 !important;
}

.address-autocomplete-wrapper input {
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 13px;
    padding-right: 13px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    
}
.address-autocomplete-wrapper input {
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 13px;
    padding-right: 13px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    
    
}

.address-autocomplete-wrapper input::placeholder {
    color: rgba(0, 0, 0, 0.87);
    opacity: 0.42;
}

.react-tel-input input::placeholder {
    color: rgba(0, 0, 0, 0.87);
    opacity: 0.42;
}

.address-autocomplete-wrapper {
    width: 100%;
    z-index: 99;
    font-size: 12px;
}

@media only screen and (max-width: 960px) {
    .address-autocomplete-wrapper {
        font-size: 16px;
    }
}

.address-autocomplete-rendered-item {
    font-size: 12px;
    padding: 5px;
}
.address-autocomplete-rendered-item-landing {
    font-size: 18px;
    padding: 8px;

}

.details-video-container {
    overflow: hidden;
    height: 580px;
    position: relative;
}

.details-video {
    /* height: 100%; */
    width: 178vh; /* 100 * 1280 / 498 */ /* 1216 / 683 */
    min-width: 100%;
    min-height: 38.9vw; /* 100 * 9 / 16 */
    position: absolute;
    left: 50%; /* % of surrounding element */
    top: 50%;
    transform: translate(-50%, -50%); /* % of current element */
}

.burger-test-image {
    width: 300px;
    height: 300px;
    background-color: black; /* fallback color */
    background-image: url('/burger.jpg');
    background-position: -30px 0px;
    background-size: cover;
    /* transform: scale(1.3); */
}

.klik-google-map [role='button'] {
    outline: none !important;
}

.klik-google-map .gm-style-iw.gm-style-iw-c,
.klik-google-map .gm-style-iw-d {
    padding: 0 !important;
    overflow: auto !important;
}

/* .menu-items-category:not(:last-child) {
    margin-bottom: 40px;
    border-bottom: 1px solid #21212110;
    padding-bottom: 40px;
} */

.search-item :hover{
  background-color: white;
  color:'#009DE0'

}