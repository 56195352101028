html,
body {
    padding: 0;
    margin: 0;
    background-color: white !important;
    /* font-family: -apple-system, Montserrat, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
        sans-serif !important; */
    /* font-family: 'Courier New', Courier, monospace !important; */
}

html {
    font-size: 16px;
}

body {
    font-size: 1rem !important;
}

@media only screen and (max-width: 960px) {
    html {
        font-size: 14px;
    }
}

@media only screen and (max-width: 960px) {
    html {
        font-size: 13px;
    }
    select,
    textarea,
    input[type='text'],
    input[type='password'],
    input[type='datetime'],
    input[type='datetime-local'],
    input[type='date'],
    input[type='month'],
    input[type='time'],
    input[type='week'],
    input[type='number'],
    input[type='email'],
    input[type='tel'],
    input[type='url'] {
        font-size: 16px;
    }
}

a,
a:hover {
    color: inherit !important;
    text-decoration: none !important;
}

* {
    box-sizing: border-box;
}

.no-outline {
    outline: none !important;
}
.cursor-pointer {
    cursor: pointer;
}

#nprogress .bar {
    height: 6px;
}

#nprogress .bar {
    background: purple;
}

#nprogress .spinner-icon {
    border-top-color: blue;
    border-left-color: blue;
}

.react-tel-input .form-control.invalid-number {
    border: 1px solid #f44336 !important;
    background-color: white !important;
    box-shadow: none;
}

.react-tel-input .form-control.invalid-number:focus {
    border-width: 2px !important;
    outline: none !important;
}

@media only screen and (max-width: 960px) {
    .react-tel-input .form-control {
        font-size: 16px !important;
    }
}

.react-tel-input {
    outline: none !important;
}

/* Couldn't find any other way to select images in google maps marker :( */
/* Feel free to improve */
.klik-google-map
    .gm-style
    img[src*='firebasestorage.googleapis.com']:not(.marker-info-wrapper img[src*='firebasestorage.googleapis.com']) {
    border-radius: 100%;
    border: 2px solid rgb(49, 49, 49) !important;
    background: white;
}

.klik-google-map .gm-style img[src*='lh3.googleusercontent.com']:not(.marker-info-wrapper img[src*='lh3.googleusercontent.com']) {
    border-radius: 100%;
    border: 2px solid rgb(49, 49, 49) !important;
    background: white;
}

.klik-custom-map-control-button {
    border-style: none;
    background: #fff;
    color: #666;
    box-shadow: 0 1px 4px -1px rgb(0 0 0 / 30%);
    cursor: pointer;
    /* font-family: Roboto, Arial, sans-serif; */
    text-align: center;
    margin-right: 10px;
    border-radius: 2px;
    padding: 0;
}

.klik-custom-map-control-button i {
    width: 40px;
    height: 40px;
    font-size: 24px;
    line-height: 40px;
}

.klik-marker-clusterer > div {
    color: white !important;
    font-size: 1.3rem !important;
    width: 60px !important;
    height: 60px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.klik-marker-clusterer > img {
    width: 60px;
}

.klik-marker-clusterer {
    text-align: center;
    width: 60px !important;
    height: 60px !important;
}

.slick-track {
    display: flex !important;
}

.slick-slide {
    height: unset !important;
}

.slick-slide > div {
    height: 100% !important;
}

.MuiToggleButton-root {
    font-size: 0.875em !important;
}

.react-datepicker {
    font-size: 1em !important;
}

.custom-marker-icon {
    border-radius: 50%;
    border: 3px solid rgb(30, 30, 30) !important;
}
