@font-face {
    font-family: 'SF_Pro';
    font-weight: 100;
    font-style: normal;
    src: local('SF_Pro'), url('/fonts/SF-Pro-Display-Ultralight.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'SF_Pro';
    font-weight: 100;
    font-style: italic;
    src: local('SF_Pro'), url('/fonts/SF-Pro-Display-UltralightItalic.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'SF_Pro';
    font-weight: 200;
    font-style: normal;
    src: local('SF_Pro'), url('/fonts/SF-Pro-Display-Thin.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'SF_Pro';
    font-weight: 200;
    font-style: italic;
    src: local('SF_Pro'), url('/fonts/SF-Pro-Display-ThinItalic.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'SF_Pro';
    font-weight: 300;
    font-style: normal;
    src: local('SF_Pro'), url('/fonts/SF-Pro-Display-Light.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'SF_Pro';
    font-weight: 300;
    font-style: italic;
    src: local('SF_Pro'), url('/fonts/SF-Pro-Display-LightItalic.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'SF_Pro';
    font-weight: normal;
    font-style: normal;
    src: local('SF_Pro'), url('/fonts/SF-Pro-Display-Regular.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'SF_Pro';
    font-weight: normal;
    font-style: italic;
    src: local('SF_Pro'), url('/fonts/SF-Pro-Display-RegularItalic.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'SF_Pro';
    font-weight: 500;
    font-style: normal;
    src: local('SF_Pro'), url('/fonts/SF-Pro-Display-Medium.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'SF_Pro';
    font-weight: 500;
    font-style: italic;
    src: local('SF_Pro'), url('/fonts/SF-Pro-Display-MediumItalic.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'SF_Pro';
    font-weight: 600;
    font-style: normal;
    src: local('SF_Pro'), url('/fonts/SF-Pro-Display-Semibold.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'SF_Pro';
    font-weight: 600;
    font-style: italic;
    src: local('SF_Pro'), url('/fonts/SF-Pro-Display-SemiboldItalic.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'SF_Pro';
    font-weight: bold;
    font-style: normal;
    src: local('SF_Pro'), url('/fonts/SF-Pro-Display-Bold.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'SF_Pro';
    font-weight: bold;
    font-style: italic;
    src: local('SF_Pro'), url('/fonts/SF-Pro-Display-BoldItalic.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'SF_Pro';
    font-weight: 800;
    font-style: normal;
    src: local('SF_Pro'), url('/fonts/SF-Pro-Display-Heavy.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'SF_Pro';
    font-weight: 800;
    font-style: italic;
    src: local('SF_Pro'), url('/fonts/SF-Pro-Display-HeavyItalic.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'SF_Pro';
    font-weight: 900;
    font-style: normal;
    src: local('SF_Pro'), url('/fonts/SF-Pro-Display-Black.otf') format('opentype');
    font-display: swap;
}

@font-face {
    font-family: 'SF_Pro';
    font-weight: 900;
    font-style: italic;
    src: local('SF_Pro'), url('/fonts/SF-Pro-Display-BlackItalic.otf') format('opentype');
    font-display: swap;
}
